import React, { HTMLProps, type CSSProperties } from "react";

type Props = HTMLProps<HTMLInputElement> & {
	debug?: boolean;
};

export const HoneyPotInput = React.forwardRef<HTMLInputElement, Props>(
	function InputComponent({ className, debug = false, ...rest }, ref) {
		const inputStyle: CSSProperties = debug
			? {}
			: { position: "absolute", left: "-9999px" };

		return (
			<div style={inputStyle} aria-hidden="true">
				<label>
					Please leave this field blank
					<input
						{...rest}
						className="border-stroke border"
						type="text"
						tabIndex={-1}
						autoComplete="off"
						ref={ref}
					/>
				</label>
			</div>
		);
	}
);
